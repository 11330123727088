import { createRouter, createWebHistory } from "vue-router";
import { RouteGuardian } from "@/utils/RouteGuardian";
import store from "@/store";

import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      guard: { except: ["guest"] },
    },
  },
  {
    path: "/sign-in",
    name: "signIn",
    component: () => import("../views/Login.vue"),
    meta: {
      layout: "CleanLayout",
      guard: {
        only: ["guest"],
        path: { name: "Home" },
      },
    },
  },
  {
    path: "/team",
    name: "Team",
    component: () => import("../views/Team.vue"),
    meta: {
      guard: { except: ["guest"] },
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
const RG = new RouteGuardian({
  guardRoute: {
    name: "signIn",
  },
});

router.beforeEach((to, from, next) => {
  const role = store.getters["account/getUserRole"] || "guest";
  RG.setRole({ role })
    .handler(to, from, next)
    .then(() => {
      next();
    })
    .catch(next);
});

export default router;
