import { USER, TOKEN } from "./mutation-types";

export default {
  [USER](state, user) {
    state.user = user;
  },
  [TOKEN](state, token) {
    state.token = token;
  },
};
