<template>
  <div class="tw-py-10">
    <header>
      <div class="tw-max-w-7xl tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8">
        <h1 class="tw-text-3xl tw-font-bold tw-leading-tight tw-text-gray-900">
          Dashboard
        </h1>
      </div>
    </header>
    <main>
      <div class="tw-max-w-7xl tw-mx-auto sm:tw-px-6 lg:tw-px-8">
        <!-- Replace with your content -->
        <div class="tw-px-4 tw-py-8 sm:tw-px-0">
          <div
            class="
              tw-border-4
              tw-border-dashed
              tw-border-gray-200
              tw-rounded-lg
              tw-h-96
            "
          />
        </div>
        <!-- /End replace -->
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
