<template>
  <slot></slot>
</template>

<script>
export default {
  name: "CleanLayout",
  components: {},
  setup() {
    return {};
  },
};
</script>
