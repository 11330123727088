import { BaseProxy } from "./BaseProxy";

export class AuthProxy extends BaseProxy {
  constructor({ parameters = {} } = {}) {
    super({
      endpoint: `login`,
      parameters,
    });
  }
}
