import { createStore } from "vuex";
import { account, accountPlugin } from "@/store/modules/account";
import { DECREMENT_LOADING, INCREMENT_LOADING } from "./mutation-types";

export default createStore({
  state: {
    loading: 0,
  },
  mutations: {
    [INCREMENT_LOADING](state) {
      state.loading = state.loading + 1;
    },
    [DECREMENT_LOADING](state) {
      state.loading = state.loading - 1;
    },
  },
  getters: {
    isLoading(state) {
      return !!state.loading;
    },
  },
  actions: {},
  modules: { account },
  plugins: [accountPlugin],
});
