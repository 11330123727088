import { TOKEN, USER } from "./mutation-types";
import { AuthProxy } from "../../../proxies/AuthProxy";
import { LogoutProxy } from "../../../proxies/LogoutProxy";
import userParser from "./utils/userParser";

export default {
  async login({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      new AuthProxy()
        .create({ item: payload })
        .then(({ token }) => {
          if (token) {
            dispatch("setToken", token).then(() => {
              resolve();
            });
          } else {
            reject();
          }
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  },
  logout({ commit, state }) {
    return new Promise((resolve) => {
      if (state.token) {
        new LogoutProxy()
          .create({})
          .then((response) => {
            if (response.success) {
              commit(TOKEN, "");
              commit(USER, null);
              resolve(true);
            }
          })
          .catch(() => {
            commit(TOKEN, "");
            commit(USER, null);
            resolve(true);
          });
      } else {
        commit(TOKEN, "");
        commit(USER, null);
        resolve(true);
      }
    });
  },
  setToken({ commit }, token) {
    return new Promise((resolve) => {
      commit(TOKEN, token);
      commit(USER, userParser(token));
      resolve();
    });
  },
};
