import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import state from "./state";
import { createAccountPlugin } from "./plugin";

const accountPlugin = createAccountPlugin({ namespace: "account" });

const account = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
export { account, accountPlugin };
