import { createApp } from "vue";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/app.css";
import i18n from "./plugins/i18n";
import qs from "qs";

const app = createApp(App);
app.use(i18n);
app.use(router);
app.use(store);
app.use(vuetify);

const query = qs.parse(window.location.search, {
  plainObjects: true,
  ignoreQueryPrefix: true,
});
if (query.token) {
  store.dispatch("account/setToken", query.token).then(() => {
    delete query.token;
    history.replaceState(
      null,
      null,
      qs.stringify(query, { plainObjects: true })
    );
    app.mount("#app");
  });
} else {
  app.mount("#app");
}
