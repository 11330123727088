export default function (jwt) {
  if (jwt) {
    let user = JSON.parse(atob(jwt.split(".")[1]));
    return {
      id: user.id,
      iss: user.iss,
      role: user.role || "user",
      exp: user.exp,
      acl: user.acl,
      name: user.name,
    };
  }
}
