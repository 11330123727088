<template>
  <div class="tw-min-h-screen tw-bg-white">
    <Disclosure
      as="nav"
      class="tw-bg-white tw-border-b tw-border-gray-200"
      v-slot="{ open }"
    >
      <div class="tw-max-w-7xl tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8">
        <div class="tw-flex tw-justify-between tw-h-16">
          <div class="tw-flex">
            <div class="tw-flex-shrink-0 tw-flex tw-items-center">
              <img
                class="tw-block lg:tw-hidden tw-h-8 tw-w-auto"
                src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                alt="Workflow"
              />
              <img
                class="tw-hidden lg:tw-block tw-h-8 tw-w-auto"
                src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
                alt="Workflow"
              />
            </div>
            <div
              class="
                tw-hidden
                sm:tw--my-px sm:tw-ml-6 sm:tw-flex sm:tw-space-x-8
              "
            >
              <router-link
                v-for="item in navigation"
                :key="item.name"
                :to="item.href"
                :class="[
                  item.current
                    ? 'tw-border-indigo-500 tw-text-gray-900'
                    : 'tw-border-transparent tw-text-gray-500 hover:tw-border-gray-300 hover:tw-text-gray-700',
                  'tw-inline-flex tw-items-center tw-px-1 tw-pt-1 tw-border-b-2 tw-text-sm tw-font-medium',
                ]"
                :aria-current="item.current ? 'page' : undefined"
              >
                {{ item.name }}
              </router-link>
            </div>
          </div>
          <div class="tw-hidden sm:tw-ml-6 sm:tw-flex sm:tw-items-center">
            <button
              type="button"
              class="
                tw-bg-white tw-p-1 tw-rounded-full tw-text-gray-400
                hover:tw-text-gray-500
                focus:tw-outline-none
                focus:tw-ring-2
                focus:tw-ring-offset-2
                focus:tw-ring-indigo-500
              "
            >
              <span class="tw-sr-only">View notifications</span>
              <BellIcon class="tw-h-6 tw-w-6" aria-hidden="true" />
            </button>

            <!-- Profile dropdown -->
            <Menu as="div" class="tw-ml-3 tw-relative">
              <div>
                <MenuButton
                  class="
                    tw-max-w-xs
                    tw-bg-white
                    tw-flex
                    tw-items-center
                    tw-text-sm
                    tw-rounded-full
                    focus:tw-outline-none
                    focus:tw-ring-2
                    focus:tw-ring-offset-2
                    focus:tw-ring-indigo-500
                  "
                >
                  <span class="tw-sr-only">Open user menu</span>
                  <img
                    class="tw-h-8 tw-w-8 tw-rounded-full"
                    :src="user.imageUrl"
                    alt=""
                  />
                </MenuButton>
              </div>
              <transition
                enter-active-class="tw-transition tw-ease-out tw-duration-200"
                enter-from-class="tw-transform tw-opacity-0 tw-scale-95"
                enter-to-class="tw-transform tw-opacity-100 tw-scale-100"
                leave-active-class="tw-transition tw-ease-in tw-duration-75"
                leave-from-class="tw-transform tw-opacity-100 tw-scale-100"
                leave-to-class="tw-transform tw-opacity-0 tw-scale-95"
              >
                <MenuItems
                  class="
                    tw-origin-top-right
                    tw-absolute
                    tw-right-0
                    tw-mt-2
                    tw-w-48
                    tw-rounded-md
                    tw-shadow-lg
                    tw-py-1
                    tw-bg-white
                    tw-ring-1
                    tw-ring-black
                    tw-ring-opacity-5
                    focus:tw-outline-none
                  "
                >
                  <MenuItem
                    v-for="item in userNavigation"
                    :key="item.name"
                    v-slot="{ active }"
                  >
                    <router-link
                      :to="item.href"
                      :class="[
                        active ? 'tw-bg-gray-100' : '',
                        'tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700',
                      ]"
                    >
                      {{ item.name }}
                    </router-link>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
          <div class="tw--mr-2 tw-flex tw-items-center sm:tw-hidden">
            <!-- Mobile menu button -->
            <DisclosureButton
              class="
                tw-bg-white
                tw-inline-flex
                tw-items-center
                tw-justify-center
                tw-p-2
                tw-rounded-md
                tw-text-gray-400
                hover:tw-text-gray-500 hover:tw-bg-gray-100
                focus:tw-outline-none
                focus:tw-ring-2
                focus:tw-ring-offset-2
                focus:tw-ring-indigo-500
              "
            >
              <span class="tw-sr-only">Open main menu</span>
              <MenuIcon
                v-if="!open"
                class="tw-block tw-h-6 tw-w-6"
                aria-hidden="true"
              />
              <XIcon v-else class="tw-block tw-h-6 tw-w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
        </div>
      </div>

      <DisclosurePanel class="sm:tw-hidden">
        <div class="tw-pt-2 tw-pb-3 tw-space-y-1">
          <a
            v-for="item in navigation"
            :key="item.name"
            :href="item.href"
            :class="[
              item.current
                ? 'tw-bg-indigo-50 tw-border-indigo-500 tw-text-indigo-700'
                : 'tw-border-transparent tw-text-gray-600 hover:tw-bg-gray-50 hover:tw-border-gray-300 hover:tw-text-gray-800',
              'tw-block tw-pl-3 tw-pr-4 tw-py-2 tw-border-l-4 tw-text-base tw-font-medium',
            ]"
            :aria-current="item.current ? 'page' : undefined"
          >
            {{ item.name }}
          </a>
        </div>
        <div class="tw-pt-4 tw-pb-3 tw-border-t tw-border-gray-200">
          <div class="tw-flex tw-items-center tw-px-4">
            <div class="tw-flex-shrink-0">
              <img
                class="tw-h-10 tw-w-10 tw-rounded-full"
                :src="user.imageUrl"
                alt=""
              />
            </div>
            <div class="tw-ml-3">
              <div class="tw-text-base tw-font-medium tw-text-gray-800">
                {{ user.name }}
              </div>
              <div class="tw-text-sm tw-font-medium tw-text-gray-500">
                {{ user.email }}
              </div>
            </div>
            <button
              type="button"
              class="
                tw-ml-auto
                tw-bg-white
                tw-flex-shrink-0
                tw-p-1
                tw-rounded-full
                tw-text-gray-400
                hover:tw-text-gray-500
                focus:tw-outline-none
                focus:tw-ring-2
                focus:tw-ring-offset-2
                focus:tw-ring-indigo-500
              "
            >
              <span class="tw-sr-only">View notifications</span>
              <BellIcon class="tw-h-6 tw-w-6" aria-hidden="true" />
            </button>
          </div>
          <div class="tw-mt-3 tw-space-y-1">
            <a
              v-for="item in userNavigation"
              :key="item.name"
              :href="item.href"
              class="
                tw-block
                tw-px-4
                tw-py-2
                tw-text-base
                tw-font-medium
                tw-text-gray-500
                hover:tw-text-gray-800 hover:tw-bg-gray-100
              "
            >
              {{ item.name }}
            </a>
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>

    <slot></slot>
  </div>
</template>

<script>
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/vue/outline";

const user = {
  name: "Tom Cook",
  email: "tom@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};
const navigation = [
  { name: "Dashboard", href: { name: "Home" }, current: true },
  { name: "Team", href: { name: "Team" }, current: false },
  { name: "Projects", href: { name: "Team" }, current: false },
  { name: "Calendar", href: { name: "Team" }, current: false },
];
const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];

export default {
  name: "AppLayout",
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    BellIcon,
    MenuIcon,
    XIcon,
  },
  setup() {
    return {
      user,
      navigation,
      userNavigation,
    };
  },
};
</script>
