<template>
  <v-app class="kiva-customer-portal">
    <v-progress-linear
      class="global-loader"
      color="primary"
      :active="isLoading"
      indeterminate
      absolute
      bottom
      height="4"
    ></v-progress-linear>

    <component :is="getLayout">
      <router-view></router-view>
    </component>
  </v-app>
</template>

<script>
import AppLayout from "./layouts/AppLayout";
import CleanLayout from "./layouts/CleanLayout";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: { AppLayout, CleanLayout },
  data: () => ({
    initialized: false,
  }),
  methods: {
    init() {
      this.initialized = true;
    },
  },
  computed: {
    ...mapGetters("account", ["isLogin"]),
    getLayout() {
      let meta = this.$route.meta;

      return meta.layout ? meta.layout : "AppLayout";
    },
  },
  watch: {
    isLogin: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.$router.push({ name: "Home" });
        } else {
          if (!this.initialized) {
            this.init();
          }
        }
      },
    },
  },
};
</script>
